import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';

const BlockchainCaller = () => {
  const [network, setNetwork] = useState('Base');
  const [contractAddress, setContractAddress] = useState('0xa47172bea713e21f6bbdd8c18f1f4db3ff064a9f');
  const [selectedFunction, setSelectedFunction] = useState('custom');
  const [customFunctionName, setCustomFunctionName] = useState('');
  const [abi, setAbi] = useState('');
  const [inputData, setInputData] = useState('');
  const [results, setResults] = useState([]);
  const [blockHeight, setBlockHeight] = useState(''); // 新增：區塊高度狀態

  const networks = {
    Base: 'https://mainnet.base.org',
    // 可以添加更多網絡
  };

  const commonFunctions = {
    custom: 'Custom Function',
    balanceOf: 'function balanceOf(address owner) public view returns (uint256)',
    ownerOf: 'function ownerOf(uint256 tokenId) public view returns (address)',
    // 可以添加更多常用函數
  };

  const handleQuery = async () => {
    const provider = new ethers.JsonRpcProvider(networks[network]);
    let contractABI;
    let functionName;

    if (selectedFunction === 'custom') {
      try {
        contractABI = JSON.parse(abi);
        functionName = customFunctionName;
      } catch (error) {
        setResults(['Error: Invalid ABI format']);
        return;
      }
    } else {
      contractABI = [commonFunctions[selectedFunction]];
      functionName = selectedFunction;
    }

    const contract = new ethers.Contract(contractAddress, contractABI, provider);
    const inputLines = inputData.split('\n').filter(line => line.trim() !== '');
    const newResults = [];

    for (const line of inputLines) {
      try {
        const params = line.split(',').map(param => param.trim());
        if (!contract[functionName]) {
          throw new Error(`Function ${functionName} not found in contract`);
        }
        
        let result;
        if (blockHeight) {
          // 如果指定了區塊高度，使用 provider.getBlockNumber() 獲取當前區塊高度
          const currentBlockHeight = await provider.getBlockNumber();
          const targetBlockHeight = parseInt(blockHeight);
          
          if (targetBlockHeight > currentBlockHeight) {
            throw new Error(`Specified block height (${blockHeight}) is in the future. Current block height is ${currentBlockHeight}.`);
          }
          
          // 使用指定的區塊高度調用合約函數
          result = await contract[functionName](...params, { blockTag: targetBlockHeight });
        } else {
          // 如果沒有指定區塊高度，使用最新的區塊
          result = await contract[functionName](...params);
        }
        
        newResults.push(`Input: ${line}, Result: ${result.toString()}, Block Height: ${blockHeight || 'Latest'}`);
      } catch (error) {
        console.error('Query error:', error);
        newResults.push(`Input: ${line}, Error: ${error.message}`);
      }
    }

    setResults(newResults);
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">區塊鏈呼叫器</h1>
      <Link to="/" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4 inline-block">
        返回 Dashboard
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div>
          <label className="block mb-2">網絡</label>
          <select
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
            className="w-full p-2 border rounded"
          >
            {Object.keys(networks).map(net => (
              <option key={net} value={net}>{net}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2">合約地址</label>
          <input
            type="text"
            value={contractAddress}
            onChange={(e) => setContractAddress(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block mb-2">查詢函數</label>
          <select
            value={selectedFunction}
            onChange={(e) => setSelectedFunction(e.target.value)}
            className="w-full p-2 border rounded"
          >
            {Object.keys(commonFunctions).map(func => (
              <option key={func} value={func}>{commonFunctions[func]}</option>
            ))}
          </select>
        </div>
        {selectedFunction === 'custom' && (
          <>
            <div>
              <label className="block mb-2">自定義函數名稱</label>
              <input
                type="text"
                value={customFunctionName}
                onChange={(e) => setCustomFunctionName(e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="輸入自定義函數名稱..."
              />
            </div>
            <div>
              <label className="block mb-2">ABI</label>
              <textarea
                value={abi}
                onChange={(e) => setAbi(e.target.value)}
                className="w-full h-32 p-2 border rounded"
                placeholder="輸入完整的合約 ABI..."
              ></textarea>
            </div>
          </>
        )}
        <div>
          <label className="block mb-2">區塊高度（可選）</label>
          <input
            type="number"
            value={blockHeight}
            onChange={(e) => setBlockHeight(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="輸入區塊高度（留空則使用最新區塊）"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block mb-2">查詢數據（每行一個輸入值）</label>
          <textarea
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
            className="w-full h-32 p-2 border rounded"
            placeholder="輸入查詢數據，每行一個..."
          ></textarea>
        </div>
      </div>
      <button 
        onClick={handleQuery}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        執行查詢
      </button>
      {results.length > 0 && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          <h2 className="text-xl font-semibold mb-2">查詢結果</h2>
          {results.map((result, index) => (
            <div key={index} className="mb-2">{result}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlockchainCaller;